.details {
  margin-top: $margin-from-header;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__overview {
    width: 70%;
    font-size: $font-size-default;
    transition: all 0.2s ease-in 0s;
    margin-bottom: 5rem;
    text-align: center;

    &:hover,
    &:focus {
      transform: scale(1.16);
    }

    @include respond(tab-port) {
      font-size: $font-size-big;
    }

    @include respond(phone) {
      width: 80%;
    }
  }

  &__trailer-container {
    width: 45rem;
    height: 35rem;
  }

  &__trailer {
    width: 100%;
    height: 100%;
    border-radius: 2rem;
    overflow: hidden;
  }

  &__img {
    width: 20rem;
    height: 30rem;
    border-radius: 2rem;
  }

  &__list {
    width: 35rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
}
