//Color
$color-primary:#404a80;
$color-primary-light: #505c9e;
$color-primary-dark: #2f365f;

$color-secondary: #298deb;
$color-secondary-light: #44a5ff;
$color-secondary-dark: #226db3;

$color-black-1: #181717;
$color-black-2: #36383b;

$color-white-1: #fff;

$color-grey-light-1: #eee;
$color-grey-light-2: #bbb;

$color-grey-dark-1: #777;
$color-grey-dark-2: #333;
$color-grey-dark-3: #333;

//Font
$font-size-small: 1.4rem;
$font-size-default: 1.6rem;
$font-size-big: 2rem;

$font-size-big-tab-land: 3.8rem;

$font-size-default-phone: 1.2rem;
$font-size-big-phone: 2rem;

$font-size-big-mini-phone: 4rem;

//Shadows
$shadow-dark: 0 2rem 6rem rgba(214, 192, 192, 0.3);
$shadow-light: 0 2rem 5rem rgba(0, 0, 0, .06);

$margin-from-header: 10rem
