.header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 90;
    width: 100%;
    height: 5rem;

    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba($color-grey-light-2, 0.5);
    background-color: $color-black-1;
    font-size: $font-size-big;
    
    &__label {
        margin-right: 1rem;
        padding: 0 1rem;
        border-radius: 2rem;
        background-color: $color-black-1;
        cursor: pointer;
        white-space: nowrap;

        @include respond(phone) {
            margin-right: 0;
        }

        &:hover {
            background-color: $color-primary;
        }
    }
}