*,
*::after, /*making the after and before elements generated by the pseudo classes to get the behavior*/
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit; /*inherits from the body*/
} 

html {
    
    font-size: 62.5%; //1rem = 10 pixels // 10px/16px = 62.5%
    //Now instead of change pixel by pixel we can just change the font-size and the size of the elements will change accordingly
    @include respond(big-desktop) {
      font-size: 75%; //1rem = 12 pixels // 12px/16px = 75%
    }
    @include respond(tab-land) {
      font-size: 56.25%; //1rem = 9 pixels // 9px/16px = 56.25%
    }
    @include respond(tab-port) {
      font-size: 50%; //1rem = 8 pixels // 8px/16px = 50%
    }
    @include respond(phone) {
      font-size: 40%; //1rem = 8 pixels // 8px/16px = 50%
    }
}

body {
  box-sizing: border-box;
  font-weight: 400;
  line-height: 1.7;
  color: $color-white-1;
  background: $color-black-1;
  font-family: 'Roboto', sans-serif;
  overflow-y: scroll;
}

::selection {
  background-color: $color-primary;
  color: $color-white-1;
}

::-webkit-input-placeholder { /* Edge */
  color: $color-grey-light-2;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $color-grey-light-2;
}

::placeholder {
  color: $color-grey-light-2;
}