.internal-error {
    margin-top: $margin-from-header;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__img {
        margin-top: 5rem;
        width: 20rem;
    }
}