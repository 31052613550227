.input {
    width: 30%;
    height: 4rem;
    font-family: inherit;
    font-size: $font-size-default;
    color: inherit;
    background-color: $color-black-2;
    border: none;
    border-radius: 2rem;
    padding: .7rem 2rem;

    &:focus {
        outline: none;
    }

    @include respond(tab-land) {
        width: 40%;
    }
    @include respond(tab-port) {
        width: 50%;
    }
    @include respond(phone) {
        width: 70%;
    }
}

.link-button {
    color: $color-white-1;
    font-size: $font-size-default;
    padding: .5rem 1rem;
    text-decoration: none;
    border-radius: 1rem;
    background-color: $color-secondary-dark;

    &:hover {
        background-color: $color-white-1;
        color: $color-secondary-dark;
    }
}

.section-title {
    cursor: default;
    font-size: 3rem;
    letter-spacing: .5rem;
}

.section-span {
    cursor: default;
    font-size: 2rem;
}

.secondary-background {
    background-color: $color-black-2;
    padding: 2rem;
    border-radius: 2rem;
}

.section-margin-1 {
    margin-top: 8rem
}

.section-margin-2 {
    margin-top: 5rem
}

.section-margin-3 {
    margin-top: 2rem
}