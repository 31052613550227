@mixin animation-show-up {
    opacity: 0;
    animation-name: show-up;
    animation-duration: 0.5s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
}

@mixin gradient-primary {
    background-image: linear-gradient(to left top, $color-grey-dark-2, $color-primary-dark);
}

@mixin gradient-secondary {
    background-image: linear-gradient(to right bottom, $color-grey-dark-2, $color-secondary-dark);
}

@mixin list-button {
    margin-bottom: 1rem;
    padding: .5rem 1rem;
    border-radius: 2rem;
    width: 12rem;
    text-align: center;
    font-size: $font-size-default;
    border: 1px solid rgba($color-grey-light-2, 0.5);
    cursor: pointer;

    &:hover {
        background-color: $color-primary;
    }
}


//media query manager
/*
0   - 600px:  Phone  
600 - 900px:  Tablet Portrait 
600 - 1200px: Tablet Landscape 

1200 - 1800:  Normal Style Applied
1800 +     : Big Desktop 
*/

/*
    $breakpoint:
    - phone
    - tab-port
    - tab-land
    - big-desktop
*/
@mixin respond($breakpoint) {

        /*
        In media queries rem and em don't get affected by the root font-size setting. 
        So they are equals to the default font-size that comes from the browser. 
        Rems don't work well in media queries so it's better to use em
        
        16px= 1em
        600px= 37.5em 
        900px= 56.25em 
        1200px= 75em 
        1800px= 112.5em 
        */
        
        //@if: an if statement like a normal programming language
        //@content: allows to pass a block of code into a mixin

        @if $breakpoint == mini-phone {
            @media only screen and (max-width:28.5em) { @content }; //300px
        }
        @if $breakpoint == phone {
            @media only screen and (max-width:37.5em) { @content }; //600px
        }
        @if $breakpoint == tab-port {
            @media only screen and (max-width:56.25em) { @content }; //900px
        }
        @if $breakpoint == tab-land {
            @media only screen and (max-width:75em) { @content }; //1200px
        }
        @if $breakpoint == big-desktop {
            @media only screen and (min-width:112.5em) { @content }; //1800px
        }
}