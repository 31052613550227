@keyframes move-in-left {
    0% {
        opacity: 0;
        transform: translateX(-10rem);
    }
    
    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes move-in-right {
    0% {
        opacity: 0;
        transform: translateX(10rem);
    }
    
    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes move-in-bottom {
    0% {
        opacity: 0;
        transform: translateY(3rem);
    }
    
    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes move-in-top {
    0% {
        opacity: 0;
        transform: translateY(-3rem);
    }
    
    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes show-up {
    0% {
        opacity: 0;
    }
    
    50% {
        opacity: 0.5;
    }

    80% {
        opacity: 0.8;
    }
    
    100% {
        opacity: 1;
    }
}