.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: auto;
    margin-left: 1rem;
    
    &__img {
        height: 3.5rem;
        
        @include respond(phone) {
            margin-left: 0;
            font-size: 2.5rem;
        }
    }

    &__name {
        margin-left: .5rem;
        font-size: 2rem;
        white-space: nowrap;
    }
}