.media {
    width: 45rem;
    height: 35rem;

    &__trailer {
        width: 100%;
        height: 100%;
        border-radius: 2rem;
        overflow: hidden;
    }

    &__img {
        width: 20rem;
        height: 30rem;
        border-radius: 2rem;
    }
}